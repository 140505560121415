

import React from "react";

export interface Job {
    title: string;
    description: JSX.Element;
    period?: string;
    whatYouWillBeDoing: string[];
    requirements: string[];
    video?: {
        url: string;
        thumbnail: string;
    };
    interested: JSX.Element | string;
}

export const jobs: Array<Job> = [  
    
          {
        title: 'Young Graduate Program',
        description: (
            <>
                 <p>
                    At IIImagine / <a href="https://aiiir.org/" target="_blank" className="text-iiimagine-red">AiiiR</a>, we aim to build shows you will remember for the rest of your life.
                </p>

                <p>
                    Our Young Graduate Programme is your opportunity to join a startup, aiming to redefine how people experience live shows at the intersection of art, technology, and storytelling.

                </p>
                <p>
                    Our team is small, highly motivated and very talented. We create the show and handle everything around it: the screenplay, the visuals, the music - but also the production, the ticket sales or the operations.
  
                </p>
                <p>
                    We are looking for someone who is not only a good person but also strives for excellence in what he/she does.
                </p>
            

            </>
        ),
        period: 'Full time',
        whatYouWillBeDoing: [
            'Global expansion: Help identify and execute strategies to bring IIImagine / AiiiR shows to iconic locations around the world',
            'Engage with our various business aspects: development, production, communication',
            'Improve the experience: play a hands-on role in ensuring our shows run seamlessly, combining operational precision with an audience-focused mindset.',
            'Participate in brainstorming sessions, crafting solutions aligned with the best interests of our audience',
        ],
        
        requirements: [
            'We are looking for someone who is very motivated, very driven to build, curious to learn, and to join in the journey. There is a clear vision for IIImagine / AiiiR and we will do our best to make you fly.',
            'Currently pursuing a university trajectory with an outstanding academic performance can be a valuable indicator of the level we seek. However, an exceptional spirit can be an even more telling indicator of potential.',
            'You can work from our office in Ghent (Belgium).',
        
        ],
        
        interested: (
            <>    

                <p>
                    Phase 1: Please email your CV and cover letter to <a href="mailto:mathias@iiimagine.org" className="text-iiimagine-red">mathias@iiimagine.org</a><br />
                    Phase 2: 30min telephone interview <br />
                    Phase 3: Case study <br />
                    Phase 4: Final interview
                    
                </p>
                <br />
                <p>
                    Looking forward to reviewing your application!
                </p>
    
            </>
        )
    }
    

]

