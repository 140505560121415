/*
 * iiimagine-frontend - Jobs.component.tsx
 * Jobs.component.tsx
 *
 * Created by Renzo Roso (renzo@3tdev.nl)
 * Copyright 3T Development - 2022-02-22
 */

import React from 'react';
import SmudgeBannerComponent from "../SmudgeBanner/SmudgeBanner.component";


export default function AboutComponent() {

    return (
        <>
            <SmudgeBannerComponent type={"about"} id={"about"} />
            <p className="w-full md:w-5/5 py-8 text-lg md:text-xl">
                IIIMAGINE was founded by the brothers Roelandt.
                <br /><br />
                "Started as a dream to create, and to create together. Inspired by the Renaissance Man, the convergence of different disciplines is our driving force: the music, visuals, light design, the manner in which we treat our visitors, the production, the operations�all created & handled by the iiiAtelier. With infectious enthusiasm radiated and a warm and welcoming atmosphere.
                <br />We often compare IIIMAGINE to a human body: with the show serving as the heart, and the various other components synergistically contributing to its vitality."

                <br /><br />
                By harmoniously integrating these elements, IIIMAGINE aspires to radiate, in a subtle yet powerful way, unwavered optimism.
            </p>
        </>
    )
}
